<template>
  <v-dialog
    v-model="display"
    width="90vw"
    v-if="fieldDefinition"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-edit-field-dialog>
      <DialogTitle @close="$emit('close')" title="Configure this Field" />
      <v-card-text>
        <FieldDesigner :definition.sync="fieldDefinition" :context="context" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-edit-field-cancel>
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          aut-action-edit-field-update
          @click="updateField"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
import FieldDesigner from "@/components/fields/Field/FieldDesigner.vue";
import { clone } from "@/util.js";
const debug = require("debug")("atman.components.edit_field_dialog");
debug("edit_field_dialog");
export default {
  name: "EditFieldDialog",
  mixins: [dialogMixin],
  props: {
    definition: {
      type: Object,
    },
    context: {
      type: String,
    },
  },
  data() {
    return {
      fieldDefinition: clone(this.definition),
      display: true,
    };
  },
  computed: {
    width: {
      get() {
        return this.fieldDefinition?.display?.width || "12";
      },
      set(value) {
        const definition = this.fieldDefinition;
        definition.display = definition.display || {};
        definition.display.width = value;
      },
    },
  },
  components: {
    FieldDesigner,
  },
  mounted() {
    debug(`In mounted of EditFieldDialog`, this.fieldDefinition);
  },
  methods: {
    updateField() {
      if (this.fieldDefinition.value == null) {
        delete this.fieldDefinition.value;
      }
      debug(`updated definition:`, this.fieldDefinition);
      this.$emit("update:field", this.fieldDefinition);
      this.$emit("close");
    },
  },
};
</script>
<style lang="css"></style>
